var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-swipe',[_c('van-swipe-item',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/aboutUs/banner-1.png")}})])],1),_c('div',{staticClass:"content-body"},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"big-title",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t('message')['home.companyIntroduce'])+" ")])]),_c('van-divider',{style:({
        color: '#ebedf0',
        borderColor: '#ebedf0',
        padding: '0 5px',
      })}),(_vm.company && _vm.company.introduce)?_c('div',{staticClass:"info-bg"},[_vm._l((_vm.company.introduce),function(item){return _c('p',{key:item,staticClass:"info-text"},[_vm._v(" "+_vm._s(item)+" ")])}),_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('message')['home.productFeature'])+" ")]),_vm._l((_vm.company.feature),function(item){return _c('p',{key:item,staticClass:"info-text"},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e()],1),_c('div',{staticClass:"content-body"},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"big-title",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t('message')['aboutUs.companyCulture'])+" ")])]),_c('van-divider',{style:({
        color: '#ebedf0',
        borderColor: '#ebedf0',
        padding: '0 5px',
      })}),(_vm.company && _vm.company.culture)?_c('div',{staticClass:"info-bg"},_vm._l((_vm.company.culture),function(item){return _c('p',{key:item,staticClass:"info-text"},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"content-body"},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"big-title",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t('message')['aboutUs.contactUs'])+" ")])]),_c('van-divider',{style:({
        color: '#ebedf0',
        borderColor: '#ebedf0',
        padding: '0 5px',
      })}),(_vm.company && _vm.company.culture)?_c('div',{staticClass:"info-bg"},[_c('p',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"contact-us-title"},[_vm._v(" "+_vm._s(_vm.$t('message')['aboutUs.phone'])+"： ")]),_c('span',{staticClass:"contact-us-info"},[_vm._v(_vm._s(_vm.baseInfo.phone))])]),_c('p',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"contact-us-title"},[_vm._v("QQ：")]),_c('span',{staticClass:"contact-us-info"},[_vm._v(_vm._s(_vm.baseInfo.qq))])]),_c('p',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"contact-us-title"},[_vm._v(" "+_vm._s(_vm.$t('message')['aboutUs.email'])+"： ")]),_c('span',{staticClass:"contact-us-info"},[_vm._v(_vm._s(_vm.baseInfo.email))])]),_c('p',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"contact-us-title"},[_vm._v(" "+_vm._s(_vm.$t('message')['aboutUs.address'])+"： ")]),_c('span',{staticClass:"contact-us-info"},[_vm._v(_vm._s(_vm.baseInfo.address))])]),_vm._m(0)]):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center","padding-bottom":"15px"}},[_c('img',{staticStyle:{"width":"150px","height":"150px"},attrs:{"alt":"company_contact","src":require("@/assets/shop-qr.png")}}),_c('p',{staticStyle:{"color":"#4d4d4d","font-size":"0.8em","margin":"0"}},[_vm._v(" 扫描二维码进入宏佳电子淘宝店 ")])])}]

export { render, staticRenderFns }