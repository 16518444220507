<template>
  <div>
    <van-swipe>
      <van-swipe-item>
        <img
          style="width: 100%"
          src="@/assets/aboutUs/cooperation_banner-1.png"
        />
      </van-swipe-item>
    </van-swipe>

    <div class="content-body">
      <div style="display: flex">
        <span class="big-title" style="flex: 1">
          {{ $t('message')['home.cooperationScheme'] }}
        </span>
      </div>

      <van-divider
        :style="{
          color: '#ebedf0',
          borderColor: '#ebedf0',
          padding: '0 5px',
        }"
      ></van-divider>

      <div v-for="item in cooperation" :key="item.title">
        <p class="title">
          {{ item.title }}
        </p>
        <div class="info-bg">
          <div style="padding: 0 15px">
            <img style="width: 100%" alt="cooperation" :src="item.img" />
          </div>
          <p class="info-text">{{ item.content }}</p>
        </div>

        <van-divider
          :style="{
            color: '#ebedf0',
            borderColor: '#ebedf0',
            padding: '0 5px',
          }"
        ></van-divider>
      </div>
    </div>
  </div>
</template>

<script>
import { dataZH, dataEN } from '@/utils/data'
import { getLanguage } from '@/utils/languageUtils'
export default {
  name: 'Cooperation',
  data() {
    return {
      cooperation: dataZH.cooperation,
    }
  },
  created() {
    this.changeLanguage(getLanguage(this.$route))
  },
  methods: {
    // 更改语言
    changeLanguage(language) {
      this.cooperation =
        language === 'enUS' ? dataEN.cooperation : dataZH.cooperation
    },
  },
  watch: {
    '$i18n.locale': function (val) {
      this.changeLanguage(val)
    },
  },
}
</script>

<style lang="less" scoped>
.content-body {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
}

.big-title {
  font-size: 1.4em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  padding: 0 5px;
}

.info-bg {
  padding: 0 10px;
}

.info-text {
  color: #4d4d4d;
  font-size: 0.9em;
  text-indent: 2em;
  line-height: 1.4em;
}
</style>
