var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-swipe',[_c('van-swipe-item',[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/aboutUs/cooperation_banner-1.png")}})])],1),_c('div',{staticClass:"content-body"},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"big-title",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t('message')['home.cooperationScheme'])+" ")])]),_c('van-divider',{style:({
        color: '#ebedf0',
        borderColor: '#ebedf0',
        padding: '0 5px',
      })}),_vm._l((_vm.cooperation),function(item){return _c('div',{key:item.title},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"info-bg"},[_c('div',{staticStyle:{"padding":"0 15px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"cooperation","src":item.img}})]),_c('p',{staticClass:"info-text"},[_vm._v(_vm._s(item.content))])]),_c('van-divider',{style:({
          color: '#ebedf0',
          borderColor: '#ebedf0',
          padding: '0 5px',
        })})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }